import React from 'react';
import { Typography, Box, CardActionArea, Card, CardActions, CardContent, createStyles } from '@material-ui/core';
import Clamp from 'react-multiline-clamp';
import { makeStyles } from '@material-ui/core/styles';
import { Link, navigate } from 'gatsby';
import ImageContainer from '../image-container';
import './card-strip.scss';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) =>
  createStyles({
    media: {
      height: 300,
      position: 'relative',
      zIndex: '10',
    },
    cornerCardBorder: {
      border: `5px solid ${theme.palette.background.default} !important`,
      borderRadius: '3px',
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        // transition: 'all 0.5s !important',
        borderColor: `${theme.palette.primary.main} !important`,
        '&::before': {
          backgroundColor: theme.palette.background.default,
        },
        '&::after': {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    titleHeight: {
      height: '30px',
    },
    bodyHeight: {
      height: '170px',
    },
    readMorePadding: {
      paddingLeft: '8px',
    },
  })
);

export default function CardStrip(props) {
  const classes = useStyles();
  return (
    <>
      <Fade bottom>
        <Card square={true} elevation={0} className='card' onClick={() => navigate(props.readMore)}>
          <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
            <Box className='borderGlow'></Box>
            <CardActionArea className='zIndex-1'>
              <ImageContainer filename={props.image} altText={props.title || '#GMIS2021'} />
              <CardContent p={2}>
                <Typography color='textPrimary' gutterBottom variant='h5'>
                  <Box fontWeight='fontWeightBold' className={`text-uppercase, ${classes.titleHeight}`}>
                    <Clamp lines={1}>{props.title}</Clamp>
                  </Box>
                </Typography>
                {props.text ? (
                  props.lines ? (
                    <Typography color='textSecondary' variant='body1' className={`${classes.bodyHeight}`}>
                      <Clamp lines={props.lines}>{props.text}</Clamp>
                    </Typography>
                  ) : (
                    <Typography color='textSecondary' variant='body1'>
                      {props.text}
                    </Typography>
                  )
                ) : null}
              </CardContent>
              {props.readMore && (
                <CardActions>
                  <Link
                    className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'
                    to={props.readMore}>
                    <Typography className={`${classes.readMorePadding}`} variant='overline'>
                      {props.ctaText || 'Read More'}
                    </Typography>
                  </Link>
                </CardActions>
              )}
              {props.hasVideo && (
                <CardActions>
                  <Link
                    className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'
                    to={props.link}>
                    <Typography variant='overline'>{props.videoLink}</Typography>
                  </Link>
                </CardActions>
              )}
            </CardActionArea>
          </Box>
        </Card>
      </Fade>
    </>
  );
}
